.w-main {
    display: flex;
    justify-content: center; /* Horizontally center the text */
    align-items: center; /* Vertically center the text */
    flex-direction: column;
    margin-top: 15%;
    margin-right: 5%;
    margin-left: 5%;
    height: auto; /* Or set a fixed height if needed */
    padding: 20px; /* Optional: adds spacing inside the box */
    text-align: center; /* Ensures multi-line text stays centered */
    /* border: solid black 2px; */
}

.w-header {
    font-size: 34px;
    font-weight: 700;
}

.w-email{
    font-style: italic;
    font-weight: 400;
}

.w-button{
    margin-top: 1rem;
    margin-left: 55%;
}