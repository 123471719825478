.mp-cardgroup-div{
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    margin-bottom: 15%;

    .mp-card{
        
        width: 20em;
        height: 45empx;
        margin-top: 10%;

        .mp-card-title{
            font-size: 50px;
            margin-top: 2rem;
            margin-bottom: 1rem;

            text-align: center;
        }

        .mp-card-body{

        }

        .mp-btn-div{
            float: left;
            margin-top: 0.4rem;
        }

        .mp-price{
            font-size: 34px;
            font-weight: 600;
            float: right;
        }
    }
}