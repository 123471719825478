.cq-main{
    margin-bottom: 5%;

    .cq-s-header{
        margin-top: 5%;
        display: flex;
        justify-content: center;


        .cq-card{

            width: fit-content;

            .cq-c-header{
                text-align: center;
                font-weight: 700;
                font-size: 34px;
            }

            .cq-c-footer{
                text-align: center;
                font-style: italic;
                
                .cq-c-span{
                    font-size: 20px;
                    margin-left: 2%;
                    margin-right: 2%;
                    transition: 0.25s;
                }

                .cq-c-span:hover{
                    cursor: pointer;
                    color: gold;
                }
            }
        }
    }

    .cq-s1-form{
        margin-top: 5%;
        display: flex;
        justify-content: center;
        
        .cq-card{
            width: 50rem;

            .cq-c-header{
                text-align: center;
                font-size: 30px;
                font-weight: 700;
            }

            .cq-c-body{
                
                .c-d-dropdown{
                    .c-select-label{
                        color: rgb(216, 209, 222);
                    }
                    .c-select{
                        padding-left: 1rem;
                        width: 100%;
                        height: 55px;
                        background-color: rgb(216, 209, 222);
                        border: 2px black solid;
                        border-radius: 10px;

                    }
                }

                .c-d-btn{
                    margin-top: 2%;
                    text-align: center;
                }
            }
        }
    }

    .cq-sm-checkbox{

        margin-top: 3%;
        display: flex;
        align-items: center;
        flex-direction: column;
        
        .cq-sm-d{
            .d-fc2{
                .fc2-input-label{
                    font-size: 24px;
                    font-weight: 600;
                }
                .fc2-checkbox{
                    margin-bottom: 2px;
                    width: 2rem;
                }
            }
        }
    }

    .cq-s2-form{
        margin-top: 3%;
        display: flex;
        justify-content: center;
        
        .cq-card2{
            width: 50rem;

            .cq-c2-header{
                text-align: center;
                font-size: 30px;
                font-weight: 700;
            }

            .cq-c2-body{
                
                .c2-d-input{
                    .c2-textfield{
                        width: 100%;
                        background-color: rgb(216, 209, 222);
                        padding: 1rem;
                        border: 2px black solid;
                        border-radius: 10px;

                    }
                    .c2-select-label{
                        color: rgb(216, 209, 222);
                    }
                    .c2-select{
                        padding-left: 1rem;
                        width: 100%;
                        height: 55px;
                        background-color: rgb(216, 209, 222);
                        border: 2px black solid;
                        border-radius: 10px;

                    }
                }

            }
        }
    }
    .c2-d-btn{
        
        margin-top: 1%;
        display: flex;
        justify-content: center;

        .c2-btn{
            text-align: center;
        }
    }
}