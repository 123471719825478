.l-header{
    font-size: 34px;
    font-weight: 500;

    margin-bottom: 0.5rem;
}

.l-footer{
    display: flex;
    margin-left: 60%;
    flex-direction: column;
}

.l-button{
    margin-bottom: 0.5rem;
    /* font-size: 15px; */
    margin-left: 15%;
}

.l-f-note{
    font-size: 12px;
    align-self: flex-end;
}