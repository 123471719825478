.r-header{
    font-size: 34px;
    font-weight: 500;

    margin-bottom: 0.5rem;
}

.r-footer{
    display: flex;
    margin-left: 50%;
    flex-direction: column;
}

.r-button{
    margin-bottom: 0.5rem;
    margin-left: 50%;
    /* font-size: 15px; */
}

.r-f-note{
    font-size: 12px;
    align-self: flex-end;
}

.r-form-groups{
    display: flex;
    flex-flow: column nowrap;
}

.r-fg-ug{
    align-self: flex-end;
}