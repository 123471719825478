.mp-header{

    text-align: center;
    background-color: #d0def6;
    color: #242930;
    padding-top: 1rem;
    padding-bottom: 1rem;

    .mp-h-header{
        font-weight: 700;
        font-size: 54px;
    }
}


.mp-body{
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 5%;
    .mp-b-header{
        margin-top: 3%;
        margin-bottom: 3%;
        font-style: italic;
        font-size: 12px;
    }
}

.mp-b-index{


    .mp-b-i-header{
        font-size: 24px;
        font-weight: 500;
    }
}

.mp-b-000{

    .mp-b-000-header{
        font-size: 24px;
        font-weight: 500;
    }
}

.mp-footer{
    text-align: center;
    margin-top: 2%;
    margin-bottom: 2%;
}

span{
    font-weight: 500;
}
