.mobile-login-main{
    margin-top: 50%;
}

.mobile-login-header{
    text-align: center;
    font-size: 34px;
    font-weight: 500;

    margin-bottom: 0.5rem;
}

.mobile-login-footer{
    display: flex;
    margin-left: 20%;
    flex-direction: column;
}

.mobile-login-button{
    margin-bottom: 0.5rem;
    /* font-size: 15px; */
    margin-left: 40%;
}

.mobile-login-f-note{
    font-size: 15px;
    align-self: flex-end;
}