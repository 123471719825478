.cqg-main{
    .cqg-s-header{
        margin-top: 5%;
        display: flex;
        justify-content: center;


        .cqg-card{

            width: fit-content;

            .cqg-c-header{
                text-align: center;
                font-weight: 700;
                font-size: 34px;
            }

            .cqg-c-footer{
                text-align: center;
                font-style: italic;
                
                .cqg-c-span{
                    font-size: 20px;
                    margin-left: 2%;
                    margin-right: 2%;
                    transition: 0.25s;
                }

                .cqg-c-span:hover{
                    cursor: pointer;
                    color: gold;
                }
            }
        }
    }

    .cqg-s-form{
        margin-top: 5%;
        display: flex;
        justify-content: center;
        
        .cqg-card2{
            width: 50rem;

            .cqg-c2-header{
                text-align: center;
                font-size: 30px;
                font-weight: 700;
            }

            .cqg-c2-body{
                
                .c2-d-input{
                    .c2-textfield{
                        width: 100%;
                        background-color: rgb(216, 209, 222);
                        padding: 1rem;
                        border: 2px black solid;
                        border-radius: 10px;

                    }
                }

                .c2-d-btn{
                    margin-top: 2%;
                    text-align: center;
                }
            }
        }
    }
}