.p-header{

    text-align: center;
    background-color: #d0def6;
    color: #242930;
    padding-top: 1rem;
    padding-bottom: 1rem;

    .p-h-header{
        font-weight: 700;
        font-size: 64px;
    }
}


.p-body{
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 1%;
    .p-b-header{
        font-size: 15px;
        margin-top: 2%;
        margin-bottom: 2%;
        font-style: italic;
    }
}

.p-b-index{


    .p-b-i-header{
        font-size: 24px;
        font-weight: 500;
    }
}

.p-b-000{

    .p-b-000-header{
        font-size: 24px;
        font-weight: 500;
    }
}

.p-footer{
    text-align: center;
    margin-top: 2%;
    margin-bottom: 2%;
}

span{
    font-weight: 500;
}
