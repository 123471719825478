.css-1dgk42-MuiPaper-root-MuiCard-root:hover{
    scale: none;
}

.qg-cardtopper{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.qg-header{
    text-align: center;
    flex-grow: 1;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
    font-size: 50px;
    color: white;
}

.qg-edit-div{
    position: absolute;
    right: 1%; /* Adjust as needed */
    .qg-edit-button{
    }
}

.qg-card{
    margin-left: 15%;
    margin-right: 15%;
}


.q-ul{
    
    margin-top: 2rem;
    list-style: none;
    font-weight: 600;
    
}


.q-li{

    list-style: none;
    display: flex;
    justify-content: center;

    .q-container {
        display: flex;
        width: 100%;
        align-content: center;
        margin-left: 13%;
        margin-bottom: 1rem;

    }
        
    .q-card{
        background-color: rgb(243, 237, 255);
        width: 80%;
        padding-left: 1rem;
        padding-right: 1rem;

        
    }

    .q-image-card{
        background-color: rgb(243, 237, 255);
        margin-bottom: 2rem;
        padding-left: 1rem;
        padding-right: 1rem;
        height: 100%;
        max-width: 80%;


        
        .q-image-container{
            margin-top: 1em;
            width: 100%;
            height: 100%;
            text-align: center;
            .q-image{
                border: black solid 2px;
                border-radius: 10px;
                width: 98%;
                text-align: center;
            }
    
        }
    }

    .q-body{
        font-size: 20px;
        margin-top: 0.5rem;
    }
    


    .q-response{
        margin-top: 0;
        height: 3rem;
        overflow-x: auto;
        overflow-y: hidden;
        width: 60%;
        .q-response-input{
            margin-bottom: 1.25rem;
            float: left;
            width: 90%;

        }
    }

    .q-submit{
        float: right;
        margin-top: -10px;
    }

    .q-save{
        float: right;
        margin-top: -10px;
    }

    .q-add{
        float: right;
        aspect-ratio: 1;
        height: 80px;
        width: 80px;
        margin-bottom: 2rem;
    }

    .q-add-btn{


    }
}

.q-side-btn-container{
    .top-container{
        .delete-btn{
            color: white;
            transition: 300ms;
        }
        .delete-btn:hover{
            color: rgba(255, 255, 255, 0.712);
        }
        .edit-btn{
            color: white;
            transition: 300ms;
        }
        .edit-btn:hover{
            color: rgba(255, 255, 255, 0.712);
        }

    }
    .bottom-container{
        .copy-btn{
            color: white;
            transition: 300ms;
        }
        .copy-btn:hover{
            color: rgba(255, 255, 255, 0.712);
        }
        .add-btn{
            color: white;
            transition: 300ms;
        }
        .add-btn:hover{
            color: rgba(255, 255, 255, 0.712);
        }
    }
}


.q-modal{
    .q-modal-header{
        font-size: 24px;
        font-weight: 600;
    }

    .q-modal-footer{

        margin-top: 2rem;
        float: right;

        .q-modal-btn{
            margin-left: 0.5rem;
            margin-right: 0.5rem;
        }
    }
}


