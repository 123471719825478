.css-19kzrtu{
    padding: 0.25em !important;
}


.m-qg-edit-div{

    display: flex;
    justify-content: right;
    margin: 1em;

    .m-qg-edit-button{
        margin-left: 0.5em;
    }
}
.m-qg-card{
    width: 100%;
}

.m-qg-cardtopper{
    display: flex;
    justify-content: center;
}

.m-qg-header{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
    font-size: 2em;
    color: white;
}



.m-q-ul{
    
    padding: 0.5em;
    list-style: none;
    font-weight: 600;
    
}


.m-q-li{

    margin-top: 1em;
    margin-bottom: 1em;
    list-style: none;

    .m-q-container {

    }
        
    .m-q-card{
        background-color: rgb(243, 237, 255);
    }

    .m-q-body{
        margin-left: 0.25em;
        margin-top: 0.25em;
        font-size: 1.15em;
    }

    .m-q-image-container{
        margin-top: 1rem;
        margin-bottom: 1rem;
        width: 100%;
        height: 100%;
        text-align: center;
        .m-q-image{
            border: black solid 2px;
            border-radius: 10px;
            width: 98%;
            text-align: center;
        }
    }
    
    .m-q-response{

        font-size: 1.5em;
        margin-left: 0.5em;
        margin-top: 0;
        overflow: scroll;

        .m-q-response-input{

        }
    }

    .m-q-textfield-div{
        margin-left: 0.3em;
        margin-top: 0.15em;
    }

    .m-q-submit{
        margin-right: 0.5em;
        margin-top: -0.65em;
        margin-bottom: 0.5em;
        float: right;
    }

    .m-q-save{
        margin-right: 0.5em;
        margin-top: -0.65em;
        float: right;
    }

    .m-q-add{
    }

    .m-q-add-btn{


    }
}

