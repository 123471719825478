.mp-cardgroup-div{
    display: flex;
    justify-content: center;
    width: 100%;

    .mp-card{
        width: 400px;
        height: 500px;
        margin-right: 8rem;
        margin-left: 8rem;
        margin-top: 10%;

        .mp-card-title{
            font-size: 50px;
            margin-top: 2rem;
            margin-bottom: 1rem;

            text-align: center;
        }

        .mp-card-body{

        }

        .mp-btn-div{
            float: left;
            margin-top: 0.4rem;
        }

        .mp-price{
            font-size: 34px;
            font-weight: 600;
            float: right;
        }
    }
}